import React from "react"
import Layout from "../../components/layout"
import { Container, Row, Col, Alert } from "reactstrap"
import { StaticImage } from 'gatsby-plugin-image'
import 'bootstrap/dist/css/bootstrap.css'


let P3 = () => (
    <Layout>
        <Container className="py-5">
            <Row>
                <Col xs={12} md={3} className="mb-3">
                    <StaticImage src="../../data/product/p14.jpg" />
                    <div style="line-height: 30px;"><span style="font-size: 16px;">道口综合视频系统（型号：CQSP-GQ-E）</span><br style="" /></div>
                </Col>
                <Col xs={12} md={9} className="mb-9">
                    <Alert color="primary">
                        产品详情
                    </Alert>
                    <ul>
                        <li>4G无线视频传输，画面清晰、流畅、犹如置身现场；</li>
                        <li>双向语音对讲，保证调度管理中心与设备安装部门的实时调度安排；</li>
                        <li> 结构设计合理，易操作，外壳坚固耐用，美观大方；</li>
                        <li>具有24小时全天候视频录像；</li>
                        <li>配有视频监管理客户端软件，人机交互界面，便于操作，为铁路管理部门管理用户档案、查询分析现场情况，提供有利依据；</li>
                        
                        </ul>
                        <Alert color="primary">
                        产品简介
                    </Alert>
                    <div class="detail_specific_desc detail_item_content show_detail_content">
                        
                        <p>&nbsp; &nbsp;  “道口综合视频系统”是我公司研制的新一代专用于铁路道口的多功能防护设备，是集4G无线视频传输、双向语音对讲、硬盘录像、视频设备。既能实现火车接近时的监控，又能实时视频监控铁路过车情况，为铁路管理部门监督掌控道口的运行情况提供了有效的依据。本系统不干扰铁路的原有设施设备，是道口实时管理、监控的理想产品</p>
                        <p style="line-height:1.5em;"><br/></p></div>

                </Col>
            </Row>
        </Container>
    </Layout>
)

export default P3





